import anlStyle from '../../style/analytics.module.css';
import { KeywordList, KeywordModal } from './KeywordList';
import { TopCharts } from './TopCharts';
import '../../../node_modules/pikaday/css/pikaday.css';
import { Calendar } from '../Units/Calendar';
import { ProdBookmark } from './ProdBookmark';
import { useEffect, useState } from 'react';
import { getRecentMonday } from '../../apis/api/utils';
import { testApi } from '../../apis/api/analytics/kpis';


const Analytics = () => {
    let today = new Date();
    let recentMonday = getRecentMonday();
    let recentSunday = new Date(recentMonday);
    recentSunday.setDate(recentMonday.getDate() - 7);
    
    const formatDate = (m, d, y) => {
        if (d < 10) {return `${m+1}월 ${d}일, ${y}`}
        else {return `${m+1}월 ${d}일, ${y}`}
    }
    
    let formattedToday = formatDate(today.getMonth(), today.getDate(), today.getFullYear());
    
    const [displayedDate, setDisplayedDate] = useState(formatDate(recentMonday.getMonth(), recentMonday.getDate(), recentMonday.getFullYear()));
    const [dateRange, setDateRange] = useState([recentSunday, recentMonday]);
    const [startDate, endDate] = dateRange;
    const [from, setFrom] = useState(startDate ? `${startDate.getFullYear()}-${startDate.getMonth() < 9 && '0'}${startDate.getMonth()+1}-${startDate.getDate()}`: null);
    const [to, setTo] = useState(endDate ? `${endDate.getFullYear()}-${startDate.getMonth() < 9 && '0'}${endDate.getMonth()+1}-${endDate.getDate()}` : null);

    const [displayedRange, setDisplayedRange] = useState(formatDate(startDate?.getMonth(), startDate?.getDate(), startDate?.getFullYear()))

    const updateDateRange = (update) => {
        setDateRange(update);
    }

    useEffect(() => {
        const newDate = `${formatDate(startDate.getMonth(), startDate.getDate(), startDate.getFullYear())} ${endDate ? (' ~ ' + formatDate(endDate.getMonth(), endDate.getDate(), endDate.getFullYear())): ''}`;
        setDisplayedRange(newDate);
        setDisplayedDate(newDate);
        setFrom(`${startDate.getFullYear()}-${startDate.getMonth() < 9 && '0'}${startDate.getMonth()+1}-${startDate.getDate()}`);
        setTo(endDate && `${endDate.getFullYear()}-${startDate.getMonth() < 9 && '0'}${endDate.getMonth()+1}-${endDate.getDate()}`);
    } ,[displayedDate, dateRange, startDate, endDate])

    // useEffect(() => {
    //     testApi();
    // }, [])
    return (
        <div className={anlStyle.Analytics__Container}>
            <div className={anlStyle.Analytics__Header}>
                <div className={anlStyle.Analytics__Header__Tab__selected}>
                    <p className='h6 fc-prim-800'>젠투 에이전트 분석</p>
                </div>
                <div className={anlStyle.Analytics__Header__Blank} />
                <Calendar 
                    type="analytics"
                    displayedDate={displayedDate}
                    setDisplayedDate={setDisplayedDate}
                    displayedRange={displayedRange}
                    setDisplayedRange={setDisplayedRange}
                    startDate={startDate}
                    endDate={endDate}
                    dateRange={dateRange}
                    updateDateRange={updateDateRange}
                />
            </div>
            <div className='spacing-32' />
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className={anlStyle.Analytics__Contents} style={{maxWidth: '1280px'}}>
                    <TopCharts
                        from={from}
                        to={to}
                    />
                </div>
            
            {/* <div className='spacing-32' />
            <div className={anlStyle.Analytics__Contents}>
                <ProdBookmark />
            </div> */}
                <div className='spacing-32' />
                <div className={anlStyle.Analytics__Contents} style={{maxWidth: '1280px'}}>
                    <KeywordList 
                        from={from}
                        to={to}
                    />
                </div>
            </div>
        </div>
    )
}

export default Analytics
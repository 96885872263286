import { useParams } from 'react-router-dom';
import agentStyle from '../../style/agent.module.css';
import unitsStyle from '../../style/units.module.css';
import chatPreviewStyle from '../../style/chatpreview.module.css';
import { TableLabel } from '../Units/Label';
import { useState } from 'react';
import { BotList } from './BotList';
import { BotStat } from './BotStat';

export const ChatPreview = () => {
    const email = useParams().email;
    const toggleActiveStyle = chatPreviewStyle.AgentToggle__active;
    const toggleInactiveStyle = chatPreviewStyle.AgentToggle__inactive;
    const [tabStatus, setTabStatus] = useState([toggleActiveStyle, toggleInactiveStyle]);

    const handleClickToggle = (e) => {
        const selectedId = e.target.className;
        if (selectedId.includes('toggle-mobile')) {setTabStatus([toggleActiveStyle, toggleInactiveStyle])}
        else {setTabStatus([toggleInactiveStyle, toggleActiveStyle])}
    }

    return (
        <>
            <div className={agentStyle.AgentList__Container} style={{height: '100%'}}>
                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{width: '100%', height: 'fit-content', flex: '0 0 43px', display: 'flex', justifyContent: 'center'}}>
                        <div className={chatPreviewStyle.AgentToggle__wrap}>
                            <div 
                                className={`${tabStatus[0]} toggle-mobile`} 
                                onClick={handleClickToggle}
                            >
                                <p className='h6 toggle-mobile'>모바일</p>
                            </div>
                            <div 
                                className={`${tabStatus[1]} toggle-desktop`} 
                                onClick={handleClickToggle}
                            >
                                <p className='h6 toggle-desktop'>데스크탑</p>
                            </div>
                        </div>
                    </div>
                    <div className='spacing-32' />
                    {
                        tabStatus[0] === toggleActiveStyle?
                        <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{width: '390px', height: '754px', borderRadius: '20px', overflow: 'hidden', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)'}}>
                                <iframe 
                                    style={{width: '100%', height: '100%', border: 'none'}}
                                    src={`${process.env.REACT_APP_CHAT_URL}/dlst/${encodeURIComponent(email)}`} />
                            </div>
                            <div className='spacing-32' />
                        </div> :
                        <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{width: '100%', height: '100%', borderRadius: '20px', overflow: 'hidden', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)'}}>
                                <iframe 
                                    style={{width: '100%', height: '100%', border: 'none'}}
                                    src={`${process.env.REACT_APP_CHAT_URL}/dlst/${encodeURIComponent(email)}`} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
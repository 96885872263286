import styled from 'styled-components';
import { colorSet, colors } from '../../style/colorVar';
import { getCardData, getWAU } from '../../apis/api/kpis';
import { getCardDataList, getWAUList } from '../../apis/services/kpis';
import { useEffect, useState } from 'react';
import { ClickRateChart, FunnelChart } from './Charts';
import { TopNav, TopNavDLST } from './TopNav';
import { ChartBox, ChartTitle, Container, FlexContainer, GridContainer, Spacer, TextDeco } from '../../style/Units';
import { getFormattedDate } from '../../apis/api/utils';

const Dashboard = () => {
    // For chart1
    const userId = sessionStorage.getItem('user');
    const [WAUList, setWAUList] = useState([0]);
    let thisMonday = new Date();
    thisMonday.setDate(thisMonday.getDate() - thisMonday.getDay() + 1);
    let [selectedDate, setSelectedDate] = useState(userId === 'demo' ? '2023-09-04' : (userId === 'dlst' ? '2023-10-02' : thisMonday));
    let [cardDataList, setCardDataList] = useState(new Array());
    let cardArr= [{'에러 발생 비율': 0}, {'상품 노출 비율': 0}, {'평균 응답 시간': 0}];
    useEffect(() => {
        const date = getFormattedDate(selectedDate);
        const WAUcall = () => {
            getWAU(date, userId)
                .then(getWAUList)
                .then((res) => setWAUList(res))
        }
        // WAUcall();
        
        const cardDataCall = () => {
            getCardData(date, userId)
                .then((res) => {
                    cardArr[0]['에러 발생 비율'] = res.errorRate;
                    cardArr[1]['상품 노출 비율'] = res.impression;
                    setCardDataList(cardArr);
                })
        }
        cardDataCall();
    }, [selectedDate]);


    return (
        <div style={{width: '100%', height: '100%'}}>
            <iframe
                src={process.env.REACT_APP_QUICKSIGHT}
                style={{width: '100%', height: '100%', border: 'none'}}
            />
            {/* <TopNavDLST page={"Dashboard"} setSelectedDate={setSelectedDate}/> */}
            {/* <ChartBox $margin="8px 0 0 0">
                <GridContainer
                    $gridCol="1fr 2fr"
                >
                    <InfoPannel>
                        <Content>
                            <p>주간 클릭 전환율</p>
                            <h1 style={{ color: colorSet.main }}>{WAUList[0]?.clickRate} <span>%</span></h1>
                        </Content>
                        <Content>
                            <p>주간 사용자 수</p>
                            <h1>{WAUList[0]?.wau} <span>명</span></h1>
                        </Content>
                    </InfoPannel>
                    <div>
                        <Container $height="324px">
                            <FunnelChart WAUList={WAUList} />
                        </Container>
                        <FunnelTable>
                            <tbody>
                                <tr>
                                    <td>전체 대화 수</td>
                                    <td>유효 대화 수</td>
                                    <td>상품 클릭 수</td>
                                </tr>
                                <tr>
                                    <td>{WAUList[0]?.total} 회</td>
                                    <td>{WAUList[0]?.served} 회</td>
                                    <td>{WAUList[0]?.clicked} 회</td>
                                </tr>
                            </tbody>
                        </FunnelTable>
                    </div>
                </GridContainer>
            </ChartBox>
            <Spacer size={16} />
            <ChartBox>
                <ChartTitle $margin="0" $padding="8px 0 16px 40px">주간 사용자 수 및 클릭 전환율(%)</ChartTitle>
                <Container $height="247px">
                    <ClickRateChart WAUList={WAUList} />
                </Container>
            </ChartBox>
            <Spacer size={16} />
            <FlexContainer $justifyContent="flex-start" $gap="14px"> 
                {
                    cardDataList?.map((x, i) => {
                        return (
                            <KPICard key={i}>
                                <FlexContainer $height="34px" $flexDir="column" $alignItems="start">
                                    <TextDeco $margin="4px 0 4px 16px">{Object.keys(x)}</TextDeco>
                                    <hr style={{ width: "100%", margin: 0 }} />
                                </FlexContainer>
                                <FlexContainer $height="96px" $flexDir="column">
                                    <TextDeco $fontSize="36px" $fontWeight="600">{Object.values(x)} %</TextDeco>
                                </FlexContainer>
                            </KPICard>
                        )
                    })
                }
            </FlexContainer> */}
        </div>
    )
};

const KPICard = styled.div`
    width: 310px;
    height: 132px;
    background: ${colorSet.prim50};
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.25);
`


const Board = styled.div`
    background-color: ${colors.base};
    margin: 8px;
    border-radius: 10px;
`

const GridBoard = styled(Board)`
    display: grid;
    grid-template-columns: ${props => props.$gridCol || ""};
    grid-template-rows: ${props => props.$gridRow || ""};
    grid-column-start: ${props => props.$gridColStart || ""};
    grid-column-end: ${props => props.$gridColEnd || ""};
`

const InfoPannel = styled.div`
    width: 100%;
    padding: 48px 40px;
`

const Content = styled.div`
    width: 100%;
    margin-bottom: 48px;

    h1 {
        font-size: 40px;
        font-weight: 900;

        span {
            font-size: 24px;
        }
    }
`

const FunnelTable = styled.table`
    width: calc(100% - 100px);
    height: 80px;
    margin-left: 80px;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
`

const SubBoard = styled.div`
    width: 100%;
    // background-color: green;
    // margin: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const KPILabel = styled.p`
    font-size: ${props => props.$fontSize || "0.8rem"};
    font-weight: ${props => props.$fontWeight || 800};
    line-height: 1.5;
`

const InfoIcon = styled.img`
    width: 16px;
    margin: 0 4px;
`

const InfoModal = styled.span`
    position: absolute;
    width: ${props => props.$width || "140px"};
    padding: 8px;
    border-radius: 5px;
    background-color: ${colors.backDark};
    color: ${colors.fontGray};
    font-size: 12px;
    word-break: keep-all;
    z-index: 10;
`

const HeadIcon = styled.div`
    width: 40px;
    height: 40px;
    margin-top: 8px;
    background: url("/img/dashboard/trending_up_FILL0_wght700_GRAD0_opsz48.svg");
`

export default Dashboard
import axios from 'axios';

export const getRate = async (userId, from, to, entity = '') => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/rate', {
            params: {
                randomId: userId,
                from: from,
                to: to,
                entity: entity,
                email: '',
                chatbotId: '',
            },
            headers: {}
        })
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getRate API: ${error}`);
    }
}

export const getAnalyticsEntity = async (userId, from, to, email = '', chatBotId = '', page = 1, pageSize = 10) => {
    try {
        const response = await axios.get(process.env.REACT_APP_ANALYTICS_URL + '/entity', {
            params: {
                userId: userId,
                from: from,
                to: to,
                // email,
                chatBotId: '',
                page: 1,
                size: 10,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getAnalyticsEntity API: ${error.message}`);
    }
}

export const getAnalyticsKeyword = async (userId, from, to, email = '', chatBotId = '', page = 1, pageSize = 10) => {
    try {
        const response = await axios.get(process.env.REACT_APP_ANALYTICS_URL + '/keyword', {
            params: {
                userId: userId,
                from: from,
                to: to,
                // email,
                chatBotId: '',
                page: 1,
                size: 10,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getAnalyticsKeyword API: ${error.message}`);
    }
}

export const getAnalyticsExample = async (userId, from, to, email = '', chatBotId = '', page = 1, pageSize = 10) => {
    try {
        const response = await axios.get(process.env.REACT_APP_ANALYTICS_URL + '/example', {
            params: {
                userId: userId,
                from: from,
                to: to,
                // email,
                chatBotId: '',
                page: 1,
                size: 10,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getAnalyticsExample API: ${error.message}`);
    }
}

export const getModalProduct = async (userId, from, to, keyword, entity, email = '', chatbotId = '') => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/keyword/product', {
            params: {
                randomId: userId,
                from: from,
                to: to,
                keyword: keyword,
                entity: entity,
                email: email,
                chatbotId: chatbotId,
            },
            headers: {},
        })
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getModalProduct API: ${error}`)
    }
}

export const getModalKeyword = async (userId, from, to, keyword, entity, email = '', chatbotId = '') => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/keyword/related', {
            params: {
                randomId: userId,
                from: from,
                to: to,
                keyword: keyword,
                entity: entity,
                email: email,
                chatbotId: chatbotId,
            },
            headers: {},
        })
        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getKeywordRelated API: ${error}`)
    }
}

export const getModalGraphData = async (userId, from, to, keyword, entity, email = '', chatbotId = '') => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/keyword/count', {
            params: {
                randomId: userId,
                from: from,
                to: to,
                keyword: keyword,
                entity: entity,
                email: email,
                chatbotId: chatbotId,
            },
            headers: {},
        })

        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getModalGraphData API: ${error}`)
    }
}

export const searchProduct = async (randomId, keyword) => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/search', {
                params: {
                    randomId: randomId,
                    keyword: keyword,
                },
                headers: {},
        })

        return response.data.data;
    } catch (error) {
        console.error(`Error while calling searchProduct API: ${error}`)
    }
}

export const postBookmark = async (randomId, itemId, bookmarkBool) => {
    try {
        const response = await axios.post(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/bookmark', 
            {
                randomId: randomId,
                itemId: itemId,
                bookmark: bookmarkBool,
            },
            {
                headers: {}
            }
        )
    } catch (error) {
        console.error(`Error while calling postBookmark API: ${error}`)
    }
}

export const getBookmark = async (randomId) => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/bookmark', {
            params: {
                randomId: randomId,
            },
            headers: {},
        })

        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getBookmark API: ${error}`)
    }
}

export const getBookmarkKeyword = async (randomId, itemId) => {
    try {
        const response = await axios.get(process.env.REACT_APP_DEMO_CONSOLE_DATA_URL + '/console/bookmark/keyword', {
            params: {
                randomId: randomId,
                itemId: itemId,
            },
            headers: {},
        })

        return response.data.data;
    } catch (error) {
        console.error(`Error while calling getBookmarkKeyword API: ${error}`)
    }
}

export const testApi = async () => {
    try {
        const response = await axios.get('https://slch7uufzk.execute-api.ap-northeast-2.amazonaws.com/'+'api/console/analytics/v1/ranking/entity', {
            params: {
                userId: 'test',
                from: '2024-09-23',
                to: '2024-09-30',
                email: 'test@test.com',
                chatBotId: '13',
            }
        })

        console.log('testapi res, ', response);
    } catch (error) {
        console.error('testapi error, ', error);
    }
}
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import anlStyle from '../../style/analytics.module.css';
import { useEffect, useState } from 'react';
import { getAnalyticsEntity, getAnalyticsExample, getAnalyticsKeyword, getRate } from '../../apis/api/analytics/kpis';
import { convertPieData } from '../../apis/api/analytics/utils';
import { bookmarkList, keywordCardData, relativeKeywordList } from '../../data/analyticsData';
import { rankChanges } from '../Units/AnalyticsAssets';

export const TopCharts = (props) => {
    const {from, to} = props;
    const [clickedCell, setClickedCell] = useState('unselected');
    const userId = sessionStorage.getItem('user');
    const [rateData, setRateData] = useState();
    const [clickedEntity, setClickedEntity] = useState('');

    // const [bookmarkList, setBookmarkList] = useState();
    const [keywordList, setKeywordList] = useState([]);
    const [isBookmarkUpdated, setIsBookmarkUpdated] = useState(false);
    const [keywordData, setKeywordData] = useState();
    const [entityData, setEntityData] = useState();

    const ENTITY_COLORS = ['#103b9c', '#154cca', '#0025E4', '#49ade5', '#bbc8e5'];
    const KEYWORDS_COLORS = ['#3c1490', '#6225c6', '#ae95d8', '#8c7dc5', '#cabcd4'];
    const SELECTED_COLORS = ['#1c89a1', '#6796a6', '#43d9be', '#346e82', '#004b68'];
    
    const entityPieData = {
        colors: ENTITY_COLORS,
        data: [
            { 
                name: '주종', 
                value: 332,
                selected: [
                    { name: '위스키', value: 332 },
                    { name: '와인', value: 275 },
                    { name: '버번 위스키', value: 120 },
                    { name: '일본 위스키', value: 80 },
                    { name: '기타', value: 193 },
                ],
            },
            { 
                name: '맛', 
                value: 275,
                selected: [
                    { name: '달달한', value: 352 },
                    { name: '달콤한', value: 325 },
                    { name: '플로럴', value: 100 },
                    { name: '달지 않은', value: 50 },
                    { name: '기타', value: 93 },
                ],
            },
            { 
                name: '상황',
                value: 120,
                selected: [
                    { name: '생일', value: 312 },
                    { name: '피크닉', value: 175 },
                    { name: '파티', value: 90 },
                    { name: '선물', value: 80 },
                    { name: '기타', value: 53 },
                ],
            },
            { 
                name: '가격', 
                value: 80,
                selected: [
                    { name: '위스키', value: 332 },
                    { name: '와인', value: 275 },
                    { name: '버번 위스키', value: 120 },
                    { name: '일본 위스키', value: 80 },
                    { name: '기타', value: 193 },
                ],
            },
            { 
                name: '기타', 
                value: 193,
                selected: [
                    { name: '위스키', value: 332 },
                    { name: '와인', value: 275 },
                    { name: '버번 위스키', value: 120 },
                    { name: '일본 위스키', value: 80 },
                    { name: '기타', value: 193 },
                ],
            },
        ]
    };

    const keywordPieData = {
        colors: KEYWORDS_COLORS,
        data: [
            { name: '위스키', value: 332 },
            { name: '와인', value: 275 },
            { name: '버번 위스키', value: 120 },
            { name: '부드러운', value: 80 },
            { name: '기타', value: 193 },
        ]
    };

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = (props, data, type) => {  
        const {cx, cy, midAngle, innerRadius, outerRadius, percent, index} = props;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <>
                <text 
                    x={x} y={y} fill="white" 
                    textAnchor={'middle'} 
                    dominantBaseline="central"
                    className='h14'
                    onClick={() => {
                        if (type === 'entity') handleClickCell(data[index].name, index)
                    }}
                    onBlur={() => {
                        setClickedCell('unselected')
                        setClickedEntity('')
                    }}
                >
                    <tspan 
                        x={x} 
                        className={type === 'entity' && 'pointer'}
                    >
                        {data[index].name}
                    </tspan>
                    <tspan 
                        x={x} dy="1em" 
                        className={type === 'entity' && 'pointer'}
                    >
                        {(percent * 100).toFixed(0)}%
                    </tspan>
                </text>
            </>
            
        );
    };

    const handleClickCell = (name, index) => {
        setClickedCell(index);
        setClickedEntity(name);
    }

    useEffect(() => {
        // getRate(userId, from, to, clickedEntity)
        //     .then(res => convertPieData(res))
        //     .then(res => setRateData(res)); 
        getAnalyticsKeyword(userId, from, to)
            .then(res => setKeywordData(res));
        getAnalyticsEntity(userId, from, to)
            .then(res => setEntityData(res));
    }, [from])

    return (
        <>
            <p className='h4 fc-gray-700'>🚀 기간별 상위 엔티티 & 키워드</p>
            <div className='spacing-4' />
            <p className='h7 fc-gray-600'>설정한 기간 동안 사용자의 대화에서 가장 많이 검색된 상위 키워드와 엔티티를 보여줍니다</p>
            <div className='spacing-16' />
            <hr className={anlStyle.Analytics__BreakLine} />
            <div className={anlStyle.ProdBookmark__ListContainer}>
                <div className={anlStyle.ProdBookmark__ListBox__left}>
                    <div className='spacing-48' />
                    <div className='flex f-between'>
                        <p className='h4 fc-gray-700'>엔티티 순위</p>
                        {/* {
                            bookmarkList?.length > 0 &&
                            <button 
                                className={`${anlStyle.ProdBookmark__Button__Add} flex f-center`}
                                // onClick={() => setIsModalOpen(true)}
                            >
                                <img src='/img/analytics/bookmark-addblue.png' width={18} height={18} />
                                <div className='spacing-4' />
                                <p className='h6 fc-prim-800'>추가하기</p>
                            </button>
                        } */}
                    </div>
                    <div className='spacing-24' />
                    <div className={anlStyle.Analytics__Table__Wrap}>
                        <table className={anlStyle.Analytics__Table}>
                            <thead>
                                <tr>
                                    <th className={anlStyle.Analytics__Table__Head__gray} />
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>순위</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>엔티티</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>언급량</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>순위등/하락</p>
                                        </th>
                                    <th className={anlStyle.Analytics__Table__Head__gray} />
                                </tr>
                            </thead>
                            <tbody>
                                    {
                                        entityData?.map((td, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.ranking.rank}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p 
                                                            className='h7 pointer'
                                                            // onClick={() => {
                                                            //     getBookmarkKeyword(userId, td.PID)
                                                            //         .then(res => setKeywordList(res))
                                                            // }}
                                                        >{td.entity}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.count}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{rankChanges(td.ranking.changes, td.ranking.isNew)}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                </tr>
                                            )
                                        })
                                    }
                            </tbody>
                        </table>
                        {
                            entityData?.length === 0 &&
                            <div className={'flex flexcol f-center'}>
                                <div className='spacing-56' />
                                <img src='/img/messages/messages-nodata.png' width={64} height={33} />
                                <div className='spacing-4' />
                                <p className='h7 fc-gray-500'>No Data</p>
                                <p className='h7 fc-gray-500'>핵심 상품 리스트가 비었어요</p>
                                <div className='spacing-16' />
                                <button 
                                    className={`${anlStyle.ProdBookmark__Button__Add} flex f-center`}
                                    // onClick={() => setIsModalOpen(true)}
                                >
                                    <img src='/img/analytics/bookmark-addblue.png' width={18} height={18} />
                                    <div className='spacing-4' />
                                    <p className='h6 fc-prim-800'>추가하기</p>
                                </button>
                                <div className='spacing-56' />
                            </div>
                        }
                    </div>
                </div>
                <div className={anlStyle.ProdBookmark__ListBox__right}>
                    <div className='spacing-48' />
                    <div className='flex f-between'>
                        <p className='h4 fc-gray-700'>키워드 순위</p>
                        {/* {
                            bookmarkList?.length > 0 &&
                            <button 
                                className={`${anlStyle.ProdBookmark__Button__Add} flex f-center`}
                                // onClick={() => setIsModalOpen(true)}
                            >
                                <img src='/img/analytics/bookmark-addblue.png' width={18} height={18} />
                                <div className='spacing-4' />
                                <p className='h6 fc-prim-800'>추가하기</p>
                            </button>
                        } */}
                    </div>
                    <div className='spacing-24' />
                    <div className={anlStyle.Analytics__Table__Wrap}>
                        <table className={anlStyle.Analytics__Table}>
                            <thead>
                                <tr>
                                    <th className={anlStyle.Analytics__Table__Head__gray} />
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>순위</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>키워드</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>언급량</p>
                                        </th>
                                        <th className={anlStyle.Analytics__Table__Head__gray}>
                                            <p className='h6'>순위등/하락</p>
                                        </th>
                                    <th className={anlStyle.Analytics__Table__Head__gray} />
                                </tr>
                            </thead>
                            <tbody>
                                    {
                                        keywordData?.map((td, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.ranking.rank}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p 
                                                            className='h7 pointer'
                                                            // onClick={() => {
                                                            //     getBookmarkKeyword(userId, td.PID)
                                                            //         .then(res => setKeywordList(res))
                                                            // }}
                                                        >{td.keyword}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{td.count}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline}>
                                                        <p className='h7'>{rankChanges(td.ranking.changes, td.ranking.isNew)}</p>
                                                    </td>
                                                    <td className={anlStyle.Analytics__Table__Body__underline} />
                                                </tr>
                                            )
                                        })
                                    }
                            </tbody>
                        </table>
                        {
                            keywordData?.length === 0 &&
                            <div className={'flex flexcol f-center'}>
                                <div className='spacing-56' />
                                <img src='/img/messages/messages-nodata.png' width={64} height={33} />
                                <div className='spacing-4' />
                                <p className='h7 fc-gray-500'>No Data</p>
                                <p className='h7 fc-gray-500'>핵심 상품 리스트가 비었어요</p>
                                <div className='spacing-16' />
                                <button 
                                    className={`${anlStyle.ProdBookmark__Button__Add} flex f-center`}
                                    // onClick={() => setIsModalOpen(true)}
                                >
                                    <img src='/img/analytics/bookmark-addblue.png' width={18} height={18} />
                                    <div className='spacing-4' />
                                    <p className='h6 fc-prim-800'>추가하기</p>
                                </button>
                                <div className='spacing-56' />
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* {
                isModalOpen ?
                <div className={anlStyle.Analytics__Dimmed}>
                    <SearchModal setIsModalOpen={setIsModalOpen}/>
                </div> :
                null
            } */}
            {/* <div className={anlStyle.Analytics__TopChart__InfoBox}>
                <img src='/img/analytics/analytics-info.png' width={24} height={24} />
                <div className='spacing-16' />
                <p className='h7'>엔티티 안의 데이터 중 하나를 클릭하면 엔티티와 관련된 키워드가 보여지게 됩니다.</p>
            </div> */}
        </>
    )
}